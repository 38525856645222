@import '../../scss/base';

.map-cluster {
  &__icon {
    --cluster-bg-color: white;
    --cluster-font-color: #{$medium};
    @include font-bold;

    border-radius: 50%;
    display: flex;
    align-items: center;
    box-shadow: rgba($medium, 0.3) 2px 3px 5px;
    color: var(--cluster-font-color);
    background-color: var(--cluster-bg-color);
    border: solid 1px $light;
    justify-content: center;

    &:hover, &--highlighted {
      --cluster-bg-color: #{$interaction};
      --cluster-font-color: white;
    }
  }
}

.marker {
  width: auto;
  background: $white;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.18);
  padding: 3px 9px 2px;
  text-align: center;
  justify-content: center;
  color: $interaction-dark;
  border-radius: $spacing-s;
  filter: drop-shadow(0px 2px 2px #0000003d);
  font-family: var(--sah-font-extra-bold) !important;
  border: 1px solid $light;

  &--hover {
    color: $interaction-dark;
    background: $white;
    border: 1px solid $medium;
  }

  &--active {
    color: $secondary-blue-light !important;
    background-color: $interaction;
    border: 1px solid $interaction;
  }

  &--booked {
    color: $light !important;
    background: $white;
    border: 2px solid $light;
    text-decoration: line-through;
    text-decoration-thickness: 2px;
  }
}
